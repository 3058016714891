
/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap'); */

@import '~primeicons/primeicons.css';

html,
body {
  margin: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
}

.p-datepicker table td.p-datepicker-today>span {
  background: #0F3C91;
  color: #ffff;
  border-color: transparent;
}

.p-datepicker {
  padding: .5rem;
  background: #fff;
  color: #495057;
  border: none;
  border-radius: 3px;
}